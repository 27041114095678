/* eslint-disable no-unreachable */
import React from 'react';
import axios from 'axios';
import { useState } from 'react';
export default function Slots({
  index,
  day,
  elementsRef,
  setSelectedSlot,
  setShowForm,
  timeDiff,
  diaryId,
  setDiaryId,
  setReservation,
}) {
  let swiftId = window?.Finder?.Data?.Dd?.swiftId;
  let selfbookKey = window?.Finder?.Data?.Dd?.selfbookKey;
  let providerType = window?.Finder.Data?.Dd?.providerType;

  const makeReservation = async (slot) => {
    const params = new URLSearchParams();
    params.set('slot', slot.slot);
    params.set('diaryId', slot.diaryId);
    setDiaryId(slot.diaryId);
    await axios
      .post(
        `/${providerType}/makeReservation/${swiftId}/?selfbook=${selfbookKey}`,
        params
      )
      .then((res) => {
        if (res.data) {
          setReservation(res.data);
        }
        console.log('Slot reserved successfully:', res.data);
      })
      .catch((error) => {
        console.log('Failed to reserve slot:', error);
      });
  };
  return (
    <div className="tw-flex tw-flex-col tw-max-h-[680px] ">
      <div className="slot-day tw-sticky tw-top-0 tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-4 tw-bg-bupa-green">
        <h2 className="tw-text-2xl tw-font-semibold">{day.dayofweek}</h2>
        <span className="tw-font-semibold">{day.date}</span>
      </div>
      <div
        className="tw-flex tw-flex-1 tw-flex-col tw-gap-2 hover:sm:tw-overflow-y-scroll sm:tw-overflow-hidden tw-overflow-y-scroll"
        ref={elementsRef.current[index]}
      >
        {day.slots.map((slot) => (
          <div
            className={`tw-bg-gray-100 hover:tw-bg-bupa-green hover:tw-text-white tw-cursor-pointer tw-flex tw-flex-col tw-gap-2 tw-items-start tw-justify-center tw-px-3 tw-py-1 tw-shadow-sm tw-mb-4 tw-transition-colors tw-duration-300 tw-ease-in-out`}
            key={slot.slot}
            onClick={() => {
              setSelectedSlot(slot);
              setShowForm(true);
              makeReservation(slot);
            }}
          >
            <p className="slot-body">{slot.start ? slot.start : slot.times}</p>
            <p className="slot-body">{slot.location}</p>
            <p className="slot-duration">Initial appointment</p>
          </div>
        ))}
      </div>
    </div>
  );
}
