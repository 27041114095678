/* eslint-disable no-debugger */
import axios from 'axios';
import React, { createRef, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import Slots from './Components/Slots';
import { postcodeValidator } from 'postcode-validator';
import ClipLoader from 'react-spinners/ClipLoader';

function Diary() {
  const [slots, setSlots] = useState(null);
  const [reservation, setReservation] = useState(null);
  const [diaryId, setDiaryId] = useState(null);
  const [locations, setLocations] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isLocation, setIsLocation] = useState(false);
  const [oneLocation, setOneLocation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingLocs, setLoadingLocs] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [nextDate, setNextDate] = useState(null);
  const [prevDate, setPrevDate] = useState([]);
  const [showError, setShowError] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedLocationIndex, setSelectedLocationIndex] = useState();
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [incrementDays, setIncremementDays] = useState(
    window.innerWidth < 580 ? 1 : 3
  );
  const [finder, setFinder] = useState(null);
  const [bookingOutcome, setBookingOutcome] = useState(null);
  const [memberData, setMemberData] = useState(null);
  const [maxDaysSearched, setMaxDaysSearched] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const titles = [
    'Mr',
    'Mrs',
    'Miss',
    'Ms',
    'Mx',
    'Ambassador',
    'Baron',
    'Baroness',
    'Chief',
    'Colonel',
    'Count',
    'Countess',
    'Dame',
    'Dr',
    'Duchess',
    'Duke',
    'Adv',
    'General',
    'Her Excellency',
    'His Excellency',
    'Her Royal Highness',
    'His Royal Highness',
    'Madam',
    'Lady',
    'Lieutenant',
    'Lord',
    'Master',
    'Prince',
    'Princess',
    'Prof',
    'Elder',
    'Pr',
    'Rev',
    'Sheikh',
    'Sheikha',
    'Sir',
    'Dame',
    'Fr',
    'Br',
    'Sr',
    'The Right Reverend',
    'Viscount',
    'Viscountess',
    'Captain',
  ];

  const femaleTitles = [
    'Mrs',
    'Miss',
    'Ms',
    'Baroness',
    'Countess',
    'Dame',
    'Duchess',
    'Her Excellency',
    'Her Royal Highness',
    'Madam',
    'Lady',
    'Princess',
    'Sheikha',
    'Viscountess',
    'Sr',
  ];

  const maleTitles = [
    'Mr',
    'Mx',
    'Baron',
    'Chief',
    'Colonel',
    'Duke',
    'His Excellency',
    'His Royal Highness',
    'Lord',
    'Master',
    'Prince',
    'Prof',
    'Elder',
    'Sheikh',
    'Sir',
    'Fr',
    'Br',
  ];

  let elementsRef = useRef([]);
  const maxSearchDays = 56;

  useEffect(() => {
    const getFinder = () => {
      setFinder({
        swiftId: window?.Finder?.Data?.Dd?.swiftId,
        selfbookKey: window?.Finder?.Data?.Dd?.selfbookKey,
        providerType: window?.Finder?.Data?.Dd?.providerType,
      });
    };
    const timer = setTimeout(getFinder(), 500);
    return clearTimeout(timer);
  }, []);

  const getLocs = async () => {
    if (finder?.swiftId && finder?.selfbookKey) {
      setLoadingLocs(true);
      await axios
        .get(
          `/Consultant/viewDiary/${finder.swiftId}?selfbook=${finder.selfbookKey}`
        )
        .then((res) => {
          let { data } = res;
          setMemberData(JSON.parse(data?.memberData));
          delete data?.memberData;
          data = Object.values(data);
          let locations = {};
          Object.keys(data).forEach((key) => {
            const item = data[key];
            if (item.locations) {
              item.locations.forEach((location) => {
                locations[location.id] = {
                  ...location,
                  appointmentTypes: item.appointmentTypes,
                  diaryId: item.id,
                };
              });
            }
          });
          console.log(locations);
          setLocations(locations);
        })
        .catch((error) => {
          setShowError(error.data);
        })
        .finally(() => setLoadingLocs(false));
    }
  };

  const getSlots = async () => {
    setLoading(true);

    if (finder?.swiftId && finder?.selfbookKey) {
      await axios
        .get(
          `/Consultant/getAppointmentSlots/${finder.swiftId}?selfbook=${finder.selfbookKey}&start=${startDate}&location=${selectedLocation.id}&dayLimit=${incrementDays}&diaryId=${selectedLocation.diaryId}`
        )
        .then((res) => {
          let slots = [];
          let { data } = res;
          slots = data.days;
          setNextDate(data?.next);
          data?.prev && setPrevDate([...prevDate, data?.prev]);
          elementsRef.current = data?.days.map(
            (_, i) => elementsRef.current[i] || createRef()
          );
          setSlots(slots);
        })
        .catch(() => {
          setShowError(
            'Failed to get available slots for this location, please refresh the page and try again. If the issue persists, please contact us or your consultant directly.'
          );
        })
        .finally(() => setLoading(false));
    }
  };
  const bookingSuccess = async () =>
    await axios
      .get(
        `/Selfbook/diaryComplete/?selfbook=${finder.selfbookKey}&swiftId=${finder.swiftId}`
      )
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });

  const bookingFailure = async () =>
    await axios
      .get(
        `/Selfbook/diaryError/?selfbook=${finder.selfbookKey}&swiftId=${finder.swiftId}`
      )
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });

  useEffect(() => {
    getLocs();
  }, [finder]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [bookingOutcome]);

  useEffect(() => {
    if (Object.keys(locations).length == 1)
      setIsLocation(true),
        setOneLocation(true),
        console.log(Object.keys(locations).length),
        setSelectedLocationIndex(Object.keys(locations)[0]),
        setSelectedLocation(locations[Object.keys(locations)[0]]);
  }, [locations]);

  useEffect(() => {
    setShowError(null);
    setShowForm(false);
    if (selectedLocation) getSlots();
  }, [selectedLocation, startDate, incrementDays]);

  const getNextDays = () => {
    if (nextDate !== null) {
      setStartDate(nextDate);
      setPrevDate([...prevDate, startDate]);
    }
  };

  const getPrevDays = () => {
    if (!prevDate.length) {
      //do catch
      setShowError();
    } else {
      let tempDates = prevDate;

      let newStartDate = tempDates.pop();
      setPrevDate(tempDates);
      setStartDate(newStartDate);
    }
  };

  const timeDiff = (time) => {
    const [start, end] = time.split(' - ');

    const startTime = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');

    const duration = moment.duration(endTime.diff(startTime));
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (hours === 0 && minutes === 0) {
      return start;
    } else if (hours === 0) {
      return `${minutes} minutes`;
    } else if (minutes === 0) {
      return `${hours} hours`;
    } else {
      return `${hours} hours, ${minutes} minutes`;
    }
  };

  useEffect(() => {
    if (slots?.length > 0) {
      const lastSlotDate = moment(slots[slots.length - 1].date, 'DD MM YYYY');
      const today = moment();
      const differenceInDays = lastSlotDate.diff(today, 'days');
      if (differenceInDays >= maxSearchDays) {
        setMaxDaysSearched(true);
      } else {
        setMaxDaysSearched(false);
      }
    }
  }, [slots]);

  const calcSize = () => {
    const { innerHeight: height, innerWidth: width } = window;
    return { height, width };
  };

  useEffect(() => {
    function handleResize() {
      let { width } = calcSize();
      if (width < 640) {
        setIncremementDays(1);
      } else {
        setIncremementDays(3);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {bookingOutcome ? (
        bookingOutcome === 'success' ? (
          <div className="tw-ml-10">
            <h1 className="tw-text-xl tw-font-bold tw-mb-1 tw-underline tw-decoration-slate-200 tw-underline-offset-8">
              Appointment booking successful<br></br>
            </h1>
            <p className="">
              <br></br>
              <strong>Appointment Details:</strong>
              <br></br>
              <br></br>
              <strong>Location:</strong> {selectedSlot.location}
              <br></br>
              <strong>Date:</strong> {selectedSlot.date}
              <br></br>
              <strong>Time:</strong> {selectedSlot.start}
              <br></br>
              <br></br>
              You may cancel the booking with the consultant by contacting the
              practice directly by phone/email.
            </p>
          </div>
        ) : bookingOutcome === 'failed' ? (
          <div className="tw-ml-10">
            <h1 className="tw-text-xl tw-font-bold tw-mb-1 tw-underline tw-decoration-slate-200 tw-underline-offset-8">
              Appointment booking failed<br></br>
            </h1>
            <p className="">
              <br></br>
              <strong>Error:</strong>
              <br></br>
              <br></br>
              {showError}
            </p>
          </div>
        ) : (
          <div className="tw-text-center">
            <div>Booking your appointment...</div>
            <br></br>
            <ClipLoader loading={true} color="#003050" speedMultiplier={0.75} />
          </div>
        )
      ) : (
        <>
          {showError && !isLocation ? (
            <ErrorComponent message={showError} />
          ) : (
            <div className="tw-w-full tw-h-full">
              <div className="container tw-h-full tw-mx-auto">
                <div className="diary-container tw-grid md:tw-grid-cols-[5%_90%_5%]">
                  <div className="skip-days tw-text-6xl md:tw-flex tw-hidden tw-mt-[16.8rem] tw-justify-end">
                    {selectedLocation && !showForm ? (
                      prevDate?.length > 0 ? (
                        <button className="prev-days" onClick={getPrevDays}>
                          <GoChevronLeft className="tw-text-black" size={32} />
                        </button>
                      ) : (
                        <button
                          className="prev-days"
                          onClick={getPrevDays}
                          disabled
                        >
                          <GoChevronLeft
                            className="tw-text-slate-100"
                            size={32}
                          />
                        </button>
                      )
                    ) : null}
                  </div>
                  <div>
                    <h1 className="tw-text-3xl tw-mb-4"> My diary</h1>
                    <div>
                      {!isLocation && (
                        <div className="tw-pb-4 tw-text-sm">
                          Please select a location below to book an appointment
                          from
                        </div>
                      )}
                      <div className="tw-grid tw-bg-gray-100 tw-h-9 tw-mb-2 tw-items-center tw-px-3 tw-font-semibold">
                        Location
                      </div>
                      {loadingLocs ? (
                        <div className="tw-grid tw-grid-cols-3 tw-gap-4">
                          {Array(3)
                            .fill(0)
                            .map((_, i) => (
                              <Skeleton key={i} divs={1} length={200} />
                            ))}
                        </div>
                      ) : (
                        <>
                          {isLocation ? (
                            <div className="location-select tw-items-center">
                              <div className="tw-grid tw-h-9 tw-mb-2">
                                <select
                                  className={`tw-w-full tw-bg-bupa-green tw-text-white tw-h-9 tw-px-3 tw-font-semibold tw-text-base tw-pr-10 ${
                                    oneLocation ? 'tw-appearance-none' : ''
                                  }`}
                                  id="location"
                                  name="location"
                                  value={selectedLocationIndex}
                                  onChange={(e) => {
                                    setSelectedLocationIndex(e.target.value);
                                    setSelectedLocation(
                                      locations[e.target.value]
                                    );
                                    setStartDate(
                                      new Date().toISOString().slice(0, 10)
                                    );
                                    setPrevDate([]);
                                    setNextDate(null);
                                  }}
                                >
                                  {Object.entries(locations)?.map(
                                    (location, i) => (
                                      <option
                                        className=" tw-bg-bupa-green tw-text-white tw-font-medium"
                                        key={i}
                                        value={location[0]}
                                      >
                                        {location[1].formattedName}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>

                              <div className="tw-flex tw-h-14">
                                <div className="tw-flex tw-bg-gray-100 tw-items-center tw-px-3 tw-mr-2 tw-font-bold tw-whitespace-nowrap">
                                  Address
                                </div>
                                <div className="tw-flex tw-flex-1 tw-bg-bupa-green tw-text-white tw-items-center tw-font-medium tw-px-3 tw-flex-wrap md:tw-text-base tw-text-sm">
                                  {
                                    locations[selectedLocationIndex]
                                      .formattedAddress
                                  }
                                </div>
                              </div>
                              {showError ? (
                                <ErrorComponent message={showError} />
                              ) : null}
                            </div>
                          ) : (
                            <div className="tw-grid sm:tw-grid-cols-3 tw-grid-cols-1 tw-gap-8 tw-my-8">
                              {Object.entries(locations)?.map((location, i) => (
                                <div
                                  key={i}
                                  className={`tw-bg-gray-100 hover:tw-bg-bupa-green hover:tw-text-white tw-cursor-pointer 
                      tw-flex tw-flex-col tw-transition-colors tw-duration-300 tw-ease-in-out`}
                                  onClick={() => {
                                    setIsLocation(true);
                                    setSelectedLocationIndex(location[0]);
                                    setSelectedLocation(locations[location[0]]);
                                  }}
                                >
                                  <div className="tw-p-4">
                                    <h2 className="tw-text-base tw-font-semibold">
                                      {location[1]?.formattedName},
                                    </h2>
                                    <span className="slot-duration">
                                      {location[1]?.formattedAddress}
                                    </span>
                                  </div>
                                  <div
                                    className=""
                                    ref={elementsRef.current[i]}
                                  ></div>
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    {showForm && (
                      <BookingForm
                        selectedSlot={selectedSlot}
                        setSelectedSlot={setSelectedSlot}
                        setShowForm={setShowForm}
                        swiftId={finder.swiftId}
                        selfbookKey={finder.selfbookKey}
                        providerType={finder.providerType}
                        diaryId={diaryId}
                        reservation={reservation}
                        setReservation={setReservation}
                        bookingOutcome={bookingOutcome}
                        setBookingOutcome={setBookingOutcome}
                        setShowError={setShowError}
                        memberData={memberData}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                        bookingSuccess={bookingSuccess}
                        bookingFailure={bookingFailure}
                        titles={titles}
                        maleTitles={maleTitles}
                        femaleTitles={femaleTitles}
                      />
                    )}
                    {selectedLocation && !showForm && !showError && (
                      <div>
                        <div className="tw-mt-8 tw-mb-4 tw-ml-4 sm:tw-ml-0 slot-body">
                          <p>Next available appointments</p>
                        </div>
                        <div className="tw-grid tw-grid-cols-[10%_80%_10%] sm:tw-grid-cols-1">
                          <div className="skip-days tw-text-6xl tw-flex md:tw-hidden tw-mt-2">
                            {selectedLocation ? (
                              prevDate?.length > 0 ? (
                                <button
                                  className="prev-days"
                                  onClick={getPrevDays}
                                >
                                  <GoChevronLeft
                                    className="tw-text-black"
                                    size={32}
                                  />
                                </button>
                              ) : (
                                <button
                                  className="prev-days cursor-not-allowed"
                                  onClick={getPrevDays}
                                  disabled
                                >
                                  <GoChevronLeft
                                    className="tw-text-slate-100"
                                    size={32}
                                  />
                                </button>
                              )
                            ) : null}
                          </div>
                          <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 tw-gap-8">
                            {loading ? (
                              <>
                                {Array(incrementDays)
                                  .fill(0)
                                  .map((_, i) => (
                                    <Skeleton key={i} divs={5} length={800} />
                                  ))}
                              </>
                            ) : (
                              <>
                                {slots?.length === 0 ? (
                                  <div className="tw-flex tw-items-center tw-justify-center tw-col-span-3 tw-my-8">
                                    <p className="tw-text-center tw-p-8 tw-border tw-border-bupa-blue sm:tw-w-2/3 tw-w-full">
                                      Sorry, there are no appointments available
                                      within the next 8 weeks. Please select
                                      another location or get in touch using the
                                      contact details on the overview page.
                                    </p>
                                  </div>
                                ) : (
                                  <>
                                    {slots?.map((day, i) => (
                                      <Slots
                                        key={i}
                                        index={i}
                                        day={day}
                                        elementsRef={elementsRef}
                                        setSelectedSlot={setSelectedSlot}
                                        setShowForm={setShowForm}
                                        timeDiff={timeDiff}
                                        selectedSlot={selectedSlot}
                                        diaryId={diaryId}
                                        setReservation={setReservation}
                                        reservation={reservation}
                                        setDiaryId={setDiaryId}
                                      />
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div className="skip-days tw-text-6xl tw-flex md:tw-hidden tw-mt-2">
                            {selectedLocation && !showForm ? (
                              slots?.length > 0 && !maxDaysSearched ? (
                                <button
                                  className="next-days"
                                  onClick={getNextDays}
                                >
                                  <GoChevronRight
                                    className="tw-text-black"
                                    size={32}
                                  />
                                </button>
                              ) : (
                                <button
                                  className="next-day cursor-not-allowed"
                                  onClick={getNextDays}
                                  disabled
                                >
                                  <GoChevronRight
                                    className="tw-text-slate-100"
                                    size={32}
                                  />
                                </button>
                              )
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="skip-days tw-text-6xl md:tw-flex tw-hidden tw-mt-[16.8rem]">
                    {selectedLocation && !showForm ? (
                      slots?.length > 0 ? (
                        <button className="next-days" onClick={getNextDays}>
                          <GoChevronRight className="tw-text-black" size={32} />
                        </button>
                      ) : (
                        <button
                          className="next-days cursor-not-allowed"
                          onClick={getNextDays}
                          disabled
                        >
                          <GoChevronRight
                            className="tw-text-slate-100"
                            size={32}
                          />
                        </button>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
export default Diary;

function Skeleton({ length, divs }) {
  return (
    <div
      className={`tw-flex tw-flex-col max-h-[${length}px] tw-rounded-md tw-gap-3`}
    >
      {new Array(divs).fill(null).map((_, i) => (
        <div
          className="tw-flex tw-h-56 tw-w-full tw-bg-gray-50 tw-animate-pulse"
          key={i}
        ></div>
      ))}
    </div>
  );
}
function ErrorComponent({ message }) {
  return (
    <div className="tw-w-full tw-flex tw-justify-center tw-items-center tw-content-center">
      <p className="tw-flex tw-justify-center tw-content-center tw-align-middle tw-text-center tw-my-8 tw-p-8 tw-border tw-border-bupa-blue tw-min-w-[66%]">
        {message}
      </p>
    </div>
  );
}

function BookingForm({
  selectedSlot,
  setSelectedSlot,
  setShowForm,
  swiftId,
  selfbookKey,
  providerType,
  diaryId,
  reservation,
  setReservation,
  setBookingOutcome,
  bookingOutcome,
  setShowError,
  memberData,
  formErrors,
  setFormErrors,
  bookingSuccess,
  bookingFailure,
  titles,
  maleTitles,
  femaleTitles,
}) {
  const [formData, setFormData] = useState({
    title: memberData.title,
    forename: memberData.firstName,
    surname: memberData.lastName,
    dobDay: '',
    dobMonth: '',
    dobYear: '',
    address_1: '',
    address_2: '',
    postcode: '',
    city: '',
    email: memberData.email,
    telephone: '',
    cancellation: false,
    diaryId: diaryId,
    slot: selectedSlot?.slot,
    start: selectedSlot?.start,
    practitioner_name: selectedSlot?.practitioner_name,
    date: selectedSlot?.date,
    dob: '',
    location: selectedSlot?.location,
    mf: '',
  });

  useEffect(() => {
    const { dobDay, dobMonth, dobYear } = formData;
    if (dobDay && dobMonth && dobYear) {
      const dob = `${dobYear}-${dobMonth.padStart(2, '0')}-${dobDay.padStart(
        2,
        '0'
      )}`;
      setFormData((prevFormData) => ({ ...prevFormData, dob }));
    }
  }, [formData.dobDay, formData.dobMonth, formData.dobYear]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      mf: maleTitles.includes(formData.title)
        ? 'M'
        : femaleTitles.includes(formData.title)
        ? 'F'
        : 'O',
    }));
  }, [formData.title]);

  let formRef = useRef();
  let ukPostcodePattern = /^[A-Z]{1,2}\d{1,2}[A-Z]? \d[A-Z]{2}$/i;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // check if there are any form errors
    let hasErrors = false;
    const updatedErrors = { ...formErrors };
    Object.keys(updatedErrors).forEach((key) => {
      if (updatedErrors[key] !== '') {
        hasErrors = true;
      }
    });

    // display form errors next to relevant form fields
    if (hasErrors) {
      setFormErrors(updatedErrors);
      return;
    }
    setBookingOutcome('inProgress');
    try {
      const tempParams = { ...formData, reservation };
      const params = new URLSearchParams(tempParams);
      await axios
        .post(
          `/${providerType}/createAppointment/${swiftId}/?selfbook=${selfbookKey}`,
          params
        )
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            setBookingOutcome('success');
            bookingSuccess();
          } else {
            // handle cases where response status is 200 but status is not 'success'
            setBookingOutcome('failed');
            bookingFailure();
            setShowError(
              'Failed to create appointment, please refresh and try again. Contact us or your consultant directly if this issue persists'
            );
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            // handle 400 error
            setBookingOutcome('failed');
            bookingFailure();
            //clean message from api
            const errorMessageObj = error.response.data.message;
            const key = Object.keys(errorMessageObj)[0];
            const errorMessage = errorMessageObj[key];
            const formattedErrorMessage = errorMessage.replace(/\n/g, ' ');
            setShowError(formattedErrorMessage);
          } else {
            // handle other errors
            setShowError(
              'Failed to create appointment, please refresh and try again. Contact us or your consultant directly if this issue persists'
            );
          }
        });
    } catch (error) {
      setShowError(
        'Failed to create appointment, please refresh and try again. Contact us or your consultant directly if this issue persists'
      );
    }
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'postcode') {
      const isValidPostcode = isValidUKPostcode(value);
      setFormErrors({
        ...formErrors,
        postcode: isValidPostcode ? '' : 'Invalid UK postcode',
      });
    }
    if (name === 'telephone') {
      const isValidNumber = isValidPhoneNumber(value);
      setFormErrors({
        ...formErrors,
        telephone: isValidNumber ? '' : 'Invalid UK phone number',
      });
    }
  };

  function isValidUKPostcode(postcode) {
    const regexUKPostcode =
      /^(GIR\s?0AA|[A-PR-UWYZ](?:\d{1,2}|[A-HK-Y]\d|[A-HK-Y]\d{1,2}|\d[A-HJKSTUW]|[A-HK-Y]\d[ABEHMNPRVWXY]))\s?\d[ABD-HJLNP-UW-Z]{2}$/i;
    return regexUKPostcode.test(postcode);
  }

  function isValidPhoneNumber(phoneNumber) {
    const regexPhoneNumber = /^(?:(?:\+|00)44|0)\d{10}$/;
    return regexPhoneNumber.test(phoneNumber);
  }

  const cancelReservation = async () => {
    if (reservation) {
      const params = new URLSearchParams();
      params.set('reservation', reservation);
      params.set('slot', selectedSlot.slot);
      params.set('diaryId', diaryId);
      await axios
        .post(
          `/${providerType}/cancelReservation/${swiftId}/?selfbook=${selfbookKey}`,
          params
        )
        .then((res) => {})
        .catch((error) => {});
    }
    setReservation(null);
  };

  return (
    <div className="">
      <br></br>
      <br></br>
      <div
        className="form-btn tw-flex tw-bg-bupa-blue tw-pl-4 tw-mx-4"
        onClick={() => {
          setSelectedSlot([]);
          setShowForm(false);
          cancelReservation();
        }}
      >
        &#60; Back
      </div>
      <div className="tw-p-4">
        <div className="">
          <h1 className="tw-text-xl tw-font-bold tw-mb-1">
            Book an appointment
          </h1>
          <p>
            <strong>Appointment Details:</strong>
            <br></br>
            <br></br>
            <strong>Location:</strong> {selectedSlot.location}
            <br></br>
            <strong>Date:</strong> {selectedSlot.date}
            <br></br>
            <strong>Time:</strong> {selectedSlot.start}
            <br></br>
            <br></br>
            To confirm your appointment, we need a few details. Please complete
            the form before booking.
          </p>
        </div>
        <br></br>

        <form ref={formRef} onSubmit={handleSubmit} className="tw-w-full">
          <div className="form-field-new">
            <label
              className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2"
              htmlFor="title"
            >
              Title
            </label>
            <div className="tw-relative">
              <select
                className="tw-form-input tw-form-select tw-bg-white tw-border tw-border-gray-500 focus:tw-border-bupa-blue tw-rounded tw-py-2 tw-pl-1 tw-text-gray-700 tw-leading-tightt"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>
                  Select
                </option>
                {titles.map((title) => (
                  <option key={title} value={title}>
                    {title}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-field-new">
            <label
              className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2"
              htmlFor="forename"
            >
              First name
            </label>
            <input
              className="tw-form-input tw-bg-white tw-border tw-border-gray-500 hover:tw-border-bupa-blue tw-rounded tw-w-full tw-py-2 tw-pl-2 tw-text-gray-700 tw-leading-tight"
              id="forename"
              name="forename"
              type="text"
              placeholder="First name"
              value={formData.forename}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-field-new">
            <label
              className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2"
              htmlFor="surname"
            >
              Last name
            </label>
            <input
              className="tw-form-input tw-bg-white tw-border tw-border-gray-500 hover:tw-border-bupa-blue tw-rounded tw-w-full tw-py-2 tw-pl-2 tw-text-gray-700 tw-leading-tight"
              id="surname"
              name="surname"
              type="text"
              placeholder="Last name"
              value={formData.surname}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-field-new">
            <label
              className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2"
              htmlFor="dob"
            >
              Date of birth
            </label>
            <div className="tw-flex tw-gap-4 tw-items-center ">
              <select
                className="tw-form-input tw-form-select tw-bg-white tw-border tw-border-gray-500 tw-rounded tw-py-2 tw-pl-1 tw-text-gray-700 tw-leading-tight focus:tw-border-bupa-blue"
                id="dobDay"
                name="dobDay"
                value={formData.dobDay}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>
                  Day
                </option>
                {Array.from({ length: 31 }, (_, i) => (
                  <option key={i + 1}>{i + 1}</option>
                ))}
              </select>
              <select
                className="tw-form-input tw-form-select tw-bg-white tw-border tw-border-gray-500 tw-rounded tw-py-2 tw-pl-1 tw-text-gray-700 tw-leading-tight focus:tw-border-bupa-blue"
                id="dobMonth"
                name="dobMonth"
                value={formData.dobMonth}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>
                  Month
                </option>
                {Array.from(Array(12), (_, i) => i + 1).map((month) => (
                  <option key={month} value={month}>
                    {new Date(2000, month - 1, 1).toLocaleString('en-us', {
                      month: 'long',
                    })}
                  </option>
                ))}
              </select>
              <select
                className="tw-form-input tw-form-select tw-bg-white tw-border tw-border-gray-500 tw-rounded tw-py-2 tw-pl-1 tw-text-gray-700 tw-leading-tight focus:tw-border-bupa-blue"
                id="dobYear"
                name="dobYear"
                value={formData.dobYear}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>
                  Year
                </option>
                {Array.from({ length: 103 }, (_, i) => (
                  <option key={i + 1920}>{i + 1920}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-field-new">
            <label
              className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2"
              htmlFor="address_1"
            >
              Address line 1
            </label>
            <input
              className="tw-form-input tw-bg-white tw-border tw-border-gray-500 hover:tw-border-bupa-blue tw-rounded tw-w-full tw-py-2 tw-pl-2 tw-text-gray-700 tw-leading-tight"
              id="address_1"
              name="address_1"
              type="text"
              placeholder="Address Line 1"
              value={formData.address_1}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-field-new">
            <label
              className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2"
              htmlFor="address2"
            >
              Address line 2
            </label>
            <input
              className="tw-form-input tw-bg-white tw-border tw-border-gray-500 hover:tw-border-bupa-blue tw-rounded tw-w-full tw-py-2 tw-pl-2 tw-text-gray-700 tw-leading-tight"
              id="address_2"
              name="address_2"
              type="text"
              placeholder="Address Line 2"
              value={formData.address_2}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-field-new">
            <label
              className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2"
              htmlFor="postcode"
            >
              Postcode
            </label>
            <input
              className="tw-form-input tw-bg-white tw-border tw-border-gray-500 hover:tw-border-bupa-blue tw-rounded tw-w-full tw-py-2 tw-pl-2 tw-text-gray-700 tw-leading-tight"
              id="postcode"
              name="postcode"
              type="text"
              placeholder="Postcode"
              value={formData.postcode}
              onChange={handleInputChange}
              required
            />
            {formErrors.postcode !== '' && (
              <span className="tw-text-red-500">{formErrors.postcode}</span>
            )}
          </div>

          <div className="form-field-new">
            <label
              className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2"
              htmlFor="city"
            >
              City
            </label>
            <input
              className="tw-form-input tw-bg-white tw-border tw-border-gray-500 hover:tw-border-bupa-blue tw-rounded tw-w-full tw-py-2 tw-pl-2 tw-text-gray-700 tw-leading-tight"
              id="city"
              name="city"
              type="text"
              placeholder="City"
              value={formData.city}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-field-new">
            <label
              className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2"
              htmlFor="email"
            >
              Email address
            </label>
            <input
              className="tw-form-input tw-bg-white tw-border tw-border-gray-500 hover:tw-border-bupa-blue tw-rounded tw-w-full tw-py-2 tw-pl-2 tw-text-gray-700 tw-leading-tight"
              id="email"
              name="email"
              type="email"
              placeholder="Email address"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-field-new">
            <label
              className="tw-block tw-text-gray-700 tw-font-bold tw-mb-2"
              htmlFor="telephone"
            >
              Contact number
            </label>
            <input
              className="tw-form-input tw-bg-white tw-border tw-border-gray-500 hover:tw-border-bupa-blue tw-rounded tw-w-full tw-py-2 tw-pl-2 tw-text-gray-700 tw-leading-tight"
              id="telephone"
              name="telephone"
              type="tel"
              placeholder="Contact number"
              value={formData.telephone}
              onChange={handleInputChange}
              required
            />
            {formErrors.telephone !== '' && (
              <span className="tw-text-red-500">{formErrors.telephone}</span>
            )}
          </div>
          <br></br>

          <div className="">
            <div className="tw-flex tw-items-center">
              <label
                className="tw-block tw-text-gray-700 tw-font-bold"
                htmlFor="cancellation"
                required
              >
                Please tick to confirm you understand that:
              </label>
            </div>
            <p>
              1. You&apos;ll be responsible for any charges as a result of
              changing or cancelling an appointment, your policy doesn&apos;t
              cover them. <br></br> <br></br> 2. You&apos;re happy for us to
              share your booking information with your chosen healthcare
              professional and the hospitals or clinics where they practise.
              They will only use this information to contact you about your
              appointments.
              <br></br>
              <br></br>
            </p>
            <label>
              I confirm
              <input
                className="tw-mr-2 tw-ml-3 tw-leading-tight"
                type="checkbox"
                id="cancellation"
                name="cancellation"
                value={formData.cancellation}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <br></br>
          <br></br>
          <div className="form-btn tw-flex tw-bg-bupa-blue tw-justify-end tw-pr-4 ">
            <button className="tw-text-white tw-font-semibold" type="submit">
              Confirm booking &#62;
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
